/* eslint-disable @graphql-eslint/naming-convention */
// prettier-ignore
/* eslint-disable */

import * as Apollo from '@apollo/client';

import { gql } from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
};

export type Query = {
  __typename?: "Query";
  dsses: DsseConnection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  subjects: SubjectConnection;
};

export type QueryDssesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<DsseWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type QueryNodesArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QuerySubjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<SubjectWhereInput>;
};

/**
 * DsseWhereInput is used for filtering Dsse objects.
 * Input was generated by ent.
 */
export type DsseWhereInput = {
  and?: InputMaybe<Array<DsseWhereInput>>;
  /** gitoid_sha256 field predicates */
  gitoidSha256?: InputMaybe<Scalars["String"]>;
  gitoidSha256Contains?: InputMaybe<Scalars["String"]>;
  gitoidSha256ContainsFold?: InputMaybe<Scalars["String"]>;
  gitoidSha256EqualFold?: InputMaybe<Scalars["String"]>;
  gitoidSha256GT?: InputMaybe<Scalars["String"]>;
  gitoidSha256GTE?: InputMaybe<Scalars["String"]>;
  gitoidSha256HasPrefix?: InputMaybe<Scalars["String"]>;
  gitoidSha256HasSuffix?: InputMaybe<Scalars["String"]>;
  gitoidSha256In?: InputMaybe<Array<Scalars["String"]>>;
  gitoidSha256LT?: InputMaybe<Scalars["String"]>;
  gitoidSha256LTE?: InputMaybe<Scalars["String"]>;
  gitoidSha256NEQ?: InputMaybe<Scalars["String"]>;
  gitoidSha256NotIn?: InputMaybe<Array<Scalars["String"]>>;
  /** payload_digests edge predicates */
  hasPayloadDigests?: InputMaybe<Scalars["Boolean"]>;
  hasPayloadDigestsWith?: InputMaybe<Array<PayloadDigestWhereInput>>;
  /** signatures edge predicates */
  hasSignatures?: InputMaybe<Scalars["Boolean"]>;
  hasSignaturesWith?: InputMaybe<Array<SignatureWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars["Boolean"]>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<DsseWhereInput>;
  or?: InputMaybe<Array<DsseWhereInput>>;
  /** payload_type field predicates */
  payloadType?: InputMaybe<Scalars["String"]>;
  payloadTypeContains?: InputMaybe<Scalars["String"]>;
  payloadTypeContainsFold?: InputMaybe<Scalars["String"]>;
  payloadTypeEqualFold?: InputMaybe<Scalars["String"]>;
  payloadTypeGT?: InputMaybe<Scalars["String"]>;
  payloadTypeGTE?: InputMaybe<Scalars["String"]>;
  payloadTypeHasPrefix?: InputMaybe<Scalars["String"]>;
  payloadTypeHasSuffix?: InputMaybe<Scalars["String"]>;
  payloadTypeIn?: InputMaybe<Array<Scalars["String"]>>;
  payloadTypeLT?: InputMaybe<Scalars["String"]>;
  payloadTypeLTE?: InputMaybe<Scalars["String"]>;
  payloadTypeNEQ?: InputMaybe<Scalars["String"]>;
  payloadTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * PayloadDigestWhereInput is used for filtering PayloadDigest objects.
 * Input was generated by ent.
 */
export type PayloadDigestWhereInput = {
  /** algorithm field predicates */
  algorithm?: InputMaybe<Scalars["String"]>;
  algorithmContains?: InputMaybe<Scalars["String"]>;
  algorithmContainsFold?: InputMaybe<Scalars["String"]>;
  algorithmEqualFold?: InputMaybe<Scalars["String"]>;
  algorithmGT?: InputMaybe<Scalars["String"]>;
  algorithmGTE?: InputMaybe<Scalars["String"]>;
  algorithmHasPrefix?: InputMaybe<Scalars["String"]>;
  algorithmHasSuffix?: InputMaybe<Scalars["String"]>;
  algorithmIn?: InputMaybe<Array<Scalars["String"]>>;
  algorithmLT?: InputMaybe<Scalars["String"]>;
  algorithmLTE?: InputMaybe<Scalars["String"]>;
  algorithmNEQ?: InputMaybe<Scalars["String"]>;
  algorithmNotIn?: InputMaybe<Array<Scalars["String"]>>;
  and?: InputMaybe<Array<PayloadDigestWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars["Boolean"]>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<PayloadDigestWhereInput>;
  or?: InputMaybe<Array<PayloadDigestWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars["String"]>;
  valueContains?: InputMaybe<Scalars["String"]>;
  valueContainsFold?: InputMaybe<Scalars["String"]>;
  valueEqualFold?: InputMaybe<Scalars["String"]>;
  valueGT?: InputMaybe<Scalars["String"]>;
  valueGTE?: InputMaybe<Scalars["String"]>;
  valueHasPrefix?: InputMaybe<Scalars["String"]>;
  valueHasSuffix?: InputMaybe<Scalars["String"]>;
  valueIn?: InputMaybe<Array<Scalars["String"]>>;
  valueLT?: InputMaybe<Scalars["String"]>;
  valueLTE?: InputMaybe<Scalars["String"]>;
  valueNEQ?: InputMaybe<Scalars["String"]>;
  valueNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * SignatureWhereInput is used for filtering Signature objects.
 * Input was generated by ent.
 */
export type SignatureWhereInput = {
  and?: InputMaybe<Array<SignatureWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars["Boolean"]>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** key_id field predicates */
  keyID?: InputMaybe<Scalars["String"]>;
  keyIDContains?: InputMaybe<Scalars["String"]>;
  keyIDContainsFold?: InputMaybe<Scalars["String"]>;
  keyIDEqualFold?: InputMaybe<Scalars["String"]>;
  keyIDGT?: InputMaybe<Scalars["String"]>;
  keyIDGTE?: InputMaybe<Scalars["String"]>;
  keyIDHasPrefix?: InputMaybe<Scalars["String"]>;
  keyIDHasSuffix?: InputMaybe<Scalars["String"]>;
  keyIDIn?: InputMaybe<Array<Scalars["String"]>>;
  keyIDLT?: InputMaybe<Scalars["String"]>;
  keyIDLTE?: InputMaybe<Scalars["String"]>;
  keyIDNEQ?: InputMaybe<Scalars["String"]>;
  keyIDNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<SignatureWhereInput>;
  or?: InputMaybe<Array<SignatureWhereInput>>;
  /** signature field predicates */
  signature?: InputMaybe<Scalars["String"]>;
  signatureContains?: InputMaybe<Scalars["String"]>;
  signatureContainsFold?: InputMaybe<Scalars["String"]>;
  signatureEqualFold?: InputMaybe<Scalars["String"]>;
  signatureGT?: InputMaybe<Scalars["String"]>;
  signatureGTE?: InputMaybe<Scalars["String"]>;
  signatureHasPrefix?: InputMaybe<Scalars["String"]>;
  signatureHasSuffix?: InputMaybe<Scalars["String"]>;
  signatureIn?: InputMaybe<Array<Scalars["String"]>>;
  signatureLT?: InputMaybe<Scalars["String"]>;
  signatureLTE?: InputMaybe<Scalars["String"]>;
  signatureNEQ?: InputMaybe<Scalars["String"]>;
  signatureNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * StatementWhereInput is used for filtering Statement objects.
 * Input was generated by ent.
 */
export type StatementWhereInput = {
  and?: InputMaybe<Array<StatementWhereInput>>;
  /** attestation_collections edge predicates */
  hasAttestationCollections?: InputMaybe<Scalars["Boolean"]>;
  hasAttestationCollectionsWith?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** dsse edge predicates */
  hasDsse?: InputMaybe<Scalars["Boolean"]>;
  hasDsseWith?: InputMaybe<Array<DsseWhereInput>>;
  /** subjects edge predicates */
  hasSubjects?: InputMaybe<Scalars["Boolean"]>;
  hasSubjectsWith?: InputMaybe<Array<SubjectWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<StatementWhereInput>;
  or?: InputMaybe<Array<StatementWhereInput>>;
  /** predicate field predicates */
  predicate?: InputMaybe<Scalars["String"]>;
  predicateContains?: InputMaybe<Scalars["String"]>;
  predicateContainsFold?: InputMaybe<Scalars["String"]>;
  predicateEqualFold?: InputMaybe<Scalars["String"]>;
  predicateGT?: InputMaybe<Scalars["String"]>;
  predicateGTE?: InputMaybe<Scalars["String"]>;
  predicateHasPrefix?: InputMaybe<Scalars["String"]>;
  predicateHasSuffix?: InputMaybe<Scalars["String"]>;
  predicateIn?: InputMaybe<Array<Scalars["String"]>>;
  predicateLT?: InputMaybe<Scalars["String"]>;
  predicateLTE?: InputMaybe<Scalars["String"]>;
  predicateNEQ?: InputMaybe<Scalars["String"]>;
  predicateNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * AttestationCollectionWhereInput is used for filtering AttestationCollection objects.
 * Input was generated by ent.
 */
export type AttestationCollectionWhereInput = {
  and?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** attestations edge predicates */
  hasAttestations?: InputMaybe<Scalars["Boolean"]>;
  hasAttestationsWith?: InputMaybe<Array<AttestationWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars["Boolean"]>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** name field predicates */
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  nameContainsFold?: InputMaybe<Scalars["String"]>;
  nameEqualFold?: InputMaybe<Scalars["String"]>;
  nameGT?: InputMaybe<Scalars["String"]>;
  nameGTE?: InputMaybe<Scalars["String"]>;
  nameHasPrefix?: InputMaybe<Scalars["String"]>;
  nameHasSuffix?: InputMaybe<Scalars["String"]>;
  nameIn?: InputMaybe<Array<Scalars["String"]>>;
  nameLT?: InputMaybe<Scalars["String"]>;
  nameLTE?: InputMaybe<Scalars["String"]>;
  nameNEQ?: InputMaybe<Scalars["String"]>;
  nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<AttestationCollectionWhereInput>;
  or?: InputMaybe<Array<AttestationCollectionWhereInput>>;
};

/**
 * AttestationWhereInput is used for filtering Attestation objects.
 * Input was generated by ent.
 */
export type AttestationWhereInput = {
  and?: InputMaybe<Array<AttestationWhereInput>>;
  /** attestation_collection edge predicates */
  hasAttestationCollection?: InputMaybe<Scalars["Boolean"]>;
  hasAttestationCollectionWith?: InputMaybe<Array<AttestationCollectionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<AttestationWhereInput>;
  or?: InputMaybe<Array<AttestationWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<Scalars["String"]>;
  typeContains?: InputMaybe<Scalars["String"]>;
  typeContainsFold?: InputMaybe<Scalars["String"]>;
  typeEqualFold?: InputMaybe<Scalars["String"]>;
  typeGT?: InputMaybe<Scalars["String"]>;
  typeGTE?: InputMaybe<Scalars["String"]>;
  typeHasPrefix?: InputMaybe<Scalars["String"]>;
  typeHasSuffix?: InputMaybe<Scalars["String"]>;
  typeIn?: InputMaybe<Array<Scalars["String"]>>;
  typeLT?: InputMaybe<Scalars["String"]>;
  typeLTE?: InputMaybe<Scalars["String"]>;
  typeNEQ?: InputMaybe<Scalars["String"]>;
  typeNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * SubjectWhereInput is used for filtering Subject objects.
 * Input was generated by ent.
 */
export type SubjectWhereInput = {
  and?: InputMaybe<Array<SubjectWhereInput>>;
  /** statement edge predicates */
  hasStatement?: InputMaybe<Scalars["Boolean"]>;
  hasStatementWith?: InputMaybe<Array<StatementWhereInput>>;
  /** subject_digests edge predicates */
  hasSubjectDigests?: InputMaybe<Scalars["Boolean"]>;
  hasSubjectDigestsWith?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  /** name field predicates */
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  nameContainsFold?: InputMaybe<Scalars["String"]>;
  nameEqualFold?: InputMaybe<Scalars["String"]>;
  nameGT?: InputMaybe<Scalars["String"]>;
  nameGTE?: InputMaybe<Scalars["String"]>;
  nameHasPrefix?: InputMaybe<Scalars["String"]>;
  nameHasSuffix?: InputMaybe<Scalars["String"]>;
  nameIn?: InputMaybe<Array<Scalars["String"]>>;
  nameLT?: InputMaybe<Scalars["String"]>;
  nameLTE?: InputMaybe<Scalars["String"]>;
  nameNEQ?: InputMaybe<Scalars["String"]>;
  nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
  not?: InputMaybe<SubjectWhereInput>;
  or?: InputMaybe<Array<SubjectWhereInput>>;
};

/**
 * SubjectDigestWhereInput is used for filtering SubjectDigest objects.
 * Input was generated by ent.
 */
export type SubjectDigestWhereInput = {
  /** algorithm field predicates */
  algorithm?: InputMaybe<Scalars["String"]>;
  algorithmContains?: InputMaybe<Scalars["String"]>;
  algorithmContainsFold?: InputMaybe<Scalars["String"]>;
  algorithmEqualFold?: InputMaybe<Scalars["String"]>;
  algorithmGT?: InputMaybe<Scalars["String"]>;
  algorithmGTE?: InputMaybe<Scalars["String"]>;
  algorithmHasPrefix?: InputMaybe<Scalars["String"]>;
  algorithmHasSuffix?: InputMaybe<Scalars["String"]>;
  algorithmIn?: InputMaybe<Array<Scalars["String"]>>;
  algorithmLT?: InputMaybe<Scalars["String"]>;
  algorithmLTE?: InputMaybe<Scalars["String"]>;
  algorithmNEQ?: InputMaybe<Scalars["String"]>;
  algorithmNotIn?: InputMaybe<Array<Scalars["String"]>>;
  and?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** subject edge predicates */
  hasSubject?: InputMaybe<Scalars["Boolean"]>;
  hasSubjectWith?: InputMaybe<Array<SubjectWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars["ID"]>;
  idGT?: InputMaybe<Scalars["ID"]>;
  idGTE?: InputMaybe<Scalars["ID"]>;
  idIn?: InputMaybe<Array<Scalars["ID"]>>;
  idLT?: InputMaybe<Scalars["ID"]>;
  idLTE?: InputMaybe<Scalars["ID"]>;
  idNEQ?: InputMaybe<Scalars["ID"]>;
  idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
  not?: InputMaybe<SubjectDigestWhereInput>;
  or?: InputMaybe<Array<SubjectDigestWhereInput>>;
  /** value field predicates */
  value?: InputMaybe<Scalars["String"]>;
  valueContains?: InputMaybe<Scalars["String"]>;
  valueContainsFold?: InputMaybe<Scalars["String"]>;
  valueEqualFold?: InputMaybe<Scalars["String"]>;
  valueGT?: InputMaybe<Scalars["String"]>;
  valueGTE?: InputMaybe<Scalars["String"]>;
  valueHasPrefix?: InputMaybe<Scalars["String"]>;
  valueHasSuffix?: InputMaybe<Scalars["String"]>;
  valueIn?: InputMaybe<Array<Scalars["String"]>>;
  valueLT?: InputMaybe<Scalars["String"]>;
  valueLTE?: InputMaybe<Scalars["String"]>;
  valueNEQ?: InputMaybe<Scalars["String"]>;
  valueNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

/** A connection to a list of items. */
export type DsseConnection = {
  __typename?: "DsseConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DsseEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DsseEdge = {
  __typename?: "DsseEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Dsse>;
};

export type Dsse = Node & {
  __typename?: "Dsse";
  gitoidSha256: Scalars["String"];
  id: Scalars["ID"];
  payloadDigests?: Maybe<Array<PayloadDigest>>;
  payloadType: Scalars["String"];
  signatures?: Maybe<Array<Signature>>;
  statement?: Maybe<Statement>;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
};

export type PayloadDigest = Node & {
  __typename?: "PayloadDigest";
  algorithm: Scalars["String"];
  dsse?: Maybe<Dsse>;
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type Signature = Node & {
  __typename?: "Signature";
  dsse?: Maybe<Dsse>;
  id: Scalars["ID"];
  keyID: Scalars["String"];
  signature: Scalars["String"];
  timestamps: Maybe<Array<Timestamp>>
};

export type Timestamp = Node & {
  __typename?: "Timestamp";
  timestamp: Scalars["String"]
}

export type Statement = Node & {
  __typename?: "Statement";
  attestationCollections?: Maybe<AttestationCollection>;
  dsse?: Maybe<Array<Dsse>>;
  id: Scalars["ID"];
  predicate: Scalars["String"];
  subjects: SubjectConnection;
};

export type StatementSubjectsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<SubjectWhereInput>;
};

export type AttestationCollection = Node & {
  __typename?: "AttestationCollection";
  attestations?: Maybe<Array<Attestation>>;
  id: Scalars["ID"];
  name: Scalars["String"];
  statement: Statement;
};

export type Attestation = Node & {
  __typename?: "Attestation";
  attestationCollection: AttestationCollection;
  id: Scalars["ID"];
  type: Scalars["String"];
};

/** A connection to a list of items. */
export type SubjectConnection = {
  __typename?: "SubjectConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubjectEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type SubjectEdge = {
  __typename?: "SubjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["Cursor"];
  /** The item at the end of the edge. */
  node?: Maybe<Subject>;
};

export type Subject = Node & {
  __typename?: "Subject";
  id: Scalars["ID"];
  name: Scalars["String"];
  statement?: Maybe<Statement>;
  subjectDigests?: Maybe<Array<SubjectDigest>>;
};

export type SubjectDigest = Node & {
  __typename?: "SubjectDigest";
  algorithm: Scalars["String"];
  id: Scalars["ID"];
  subject?: Maybe<Subject>;
  value: Scalars["String"];
};

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]>;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = "ASC",
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = "DESC",
}

export type BySubjectDigestQueryVariables = Exact<{
  digest: Scalars["String"];
}>;

export type BySubjectDigestQuery = {
  __typename?: "Query";
  dsses: {
    __typename?: "DsseConnection";
    totalCount: number;
    pageInfo: { __typename?: "PageInfo"; endCursor?: any | null };
    edges?: Array<{
      __typename?: "DsseEdge";
      node?: {
        __typename?: "Dsse";
        gitoidSha256: string;
        statement?: {
          __typename?: "Statement";
          subjects: {
            __typename?: "SubjectConnection";
            totalCount: number;
            pageInfo: { __typename?: "PageInfo"; endCursor?: any | null };
            edges?: Array<{
              __typename?: "SubjectEdge";
              node?: { __typename?: "Subject"; name: string; subjectDigests?: Array<{ __typename?: "SubjectDigest"; value: string }> | null } | null;
            } | null> | null;
          };
          attestationCollections?: {
            __typename?: "AttestationCollection";
            name: string;
            attestations?: Array<{ __typename?: "Attestation"; type: string }> | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export const BySubjectDigestDocument = gql`
  query bySubjectDigest($digest: String!) {
    dsses(
      where: {
        hasStatementWith: { hasAttestationCollectionsWith: { and: [{ hasStatementWith: { hasSubjectsWith: { hasSubjectDigestsWith: { value: $digest } } } }] } }
      }
    ) {
      totalCount
      pageInfo {
        endCursor
      }
      edges {
        node {
          gitoidSha256
          statement {
            subjects(where: { not: { nameHasPrefix: "https://witness.dev/attestations/product/v0.1" } }) {
              totalCount
              pageInfo {
                endCursor
              }
              edges {
                node {
                  name
                  subjectDigests {
                    value
                  }
                }
              }
            }
            attestationCollections {
              name
              attestations {
                type
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useBySubjectDigestQuery__
 *
 * To run a query within a React component, call `useBySubjectDigestQuery` and pass it any options that fit your needs.
 * When your component renders, `useBySubjectDigestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBySubjectDigestQuery({
 *   variables: {
 *      digest: // value for 'digest'
 *   },
 * });
 */
export function useBySubjectDigestQuery(baseOptions: Apollo.QueryHookOptions<BySubjectDigestQuery, BySubjectDigestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BySubjectDigestQuery, BySubjectDigestQueryVariables>(BySubjectDigestDocument, options);
}
export function useBySubjectDigestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BySubjectDigestQuery, BySubjectDigestQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BySubjectDigestQuery, BySubjectDigestQueryVariables>(BySubjectDigestDocument, options);
}
export type BySubjectDigestQueryHookResult = ReturnType<typeof useBySubjectDigestQuery>;
export type BySubjectDigestLazyQueryHookResult = ReturnType<typeof useBySubjectDigestLazyQuery>;
export type BySubjectDigestQueryResult = Apollo.QueryResult<BySubjectDigestQuery, BySubjectDigestQueryVariables>;
