/* eslint-disable @typescript-eslint/no-empty-function */
import { Configuration, FrontendApi, Identity, Session } from '@ory/client';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import useGitHub from '../../hooks/use-git-hub/useGitHub';
import useGitLab from '../../hooks/use-git-lab/useGitLab';

interface UserContextProps {
  identity?: Identity;
  logoutUrl?: string;
  session?: Session;
  setSession: (session?: Session) => void;
  username?: string;
  avatarUrl?: string;
  isGithub?: boolean;
  isGitLab?: boolean;
}

const UserContext = createContext<UserContextProps>({
  setSession: () => {},
});

// Get your Ory url from .env
// Or localhost for local development
const basePath = process.env.REACT_APP_ORY_URL || '/kratos';
const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  })
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const getUserName = (identity: Identity) => (identity?.traits?.username || identity?.traits?.email) as string;

/**
 * The User Context
 * Provides the user globally
 * Should be undefined if the user is not signed in
 *
 * @param {*} { children }
 * @returns
 */
export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [username, setUsername] = useState<string>();
  const [identity, setIdentity] = useState<Identity>();
  const [isGithub, setIsGitHub] = useState(false);
  const [isGitLab, setIsGitLab] = useState(false);
  const [github] = useGitHub(
    `
  {
    viewer {
      avatarUrl
      starredRepositories {
        totalCount
      }
    }
  }
  `,
    isGithub
  );

  const gitLabQuery = `
  {
    currentUser {
      avatarUrl
    }
  }
  `;
  const [gitlab] = useGitLab(gitLabQuery, isGitLab && username !== undefined);

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [session, setSession] = useState<Session | undefined>(undefined);
  const [logoutUrl, setLogoutUrl] = useState<string | undefined>();

  /**
   * This hook makes sure to set the token for the registrar if our user id changes
   */
  // Second, gather session data, if the user is not logged in, redirect to login
  useEffect(() => {
    const getSession = async () => {
      const { data } = await ory.toSession();
      if (data) {
        // User has a session!
        setSession(data);
        const { data: logoutFlow } = await ory.createBrowserLogoutFlow();
        // Get also the logout url
        setLogoutUrl(logoutFlow?.logout_url);
      }
    };

    getSession().catch((err) => {
      // NOTE: This is where we handle login errors gracefully. Ory docs suggests to go to the login page. See: https://www.ory.sh/docs/kratos/quickstart
      console.error(err);
      // Redirect to login page
      window.location.replace('/login/login?return_to=' + window.location.origin);
    });
  }, [setLogoutUrl, setSession]);

  useEffect(() => {
    if (session) {
      console.log(session);
      setIdentity(session?.identity);
    }
  }, [session]);

  useEffect(() => {
    if (identity) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setIsGitHub((session?.authentication_methods?.[0] as any)?.provider == 'github');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setIsGitLab((session?.authentication_methods?.[0] as any)?.provider == 'gitlab');
    }
  }, [identity, session?.authentication_methods]);

  useEffect(() => {
    if (identity) {
      console.log(identity);
      setUsername(getUserName(identity));
    }
  }, [identity]);

  useEffect(() => {
    if (isGithub && github) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAvatarUrl(github?.payload?.viewer?.avatarUrl);
    }
  }, [github, isGithub]);

  useEffect(() => {
    if (isGitLab && gitlab && gitlab?.gitlabUrl) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions
      setAvatarUrl(`${gitlab?.gitlabUrl}${gitlab?.payload?.currentUser?.avatarUrl}`);
    }
  }, [gitlab, isGitLab]);

  return <UserContext.Provider value={{ session, setSession, username, identity, logoutUrl, avatarUrl, isGithub, isGitLab }}>{children}</UserContext.Provider>;
};

/**
 * A shortcut for importing the UserContext global state
 * @returns the UserContext global state
 */
export const useUser = () => useContext(UserContext);
