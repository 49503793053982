/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import useApiStatus, { ApiStatus } from '../use-api-status/useApiStatus';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export type GitHubApiProps = {
  apiStatus: ApiStatus;
  query: string;
  payload?: any;
};

/**
 * A hook for using the Github graphql api through judge-api
 *
 */
const useGitHub = (q: string, isEnabled: boolean): [GitHubApiProps, (q: string) => void] => {
  const [apiStatus, setIsLoading, setHasError, setError] = useApiStatus();
  const [results, setApiResults] = useState({} as any);

  const [query, setQuery] = useState(q);
  const judgeApiProps: GitHubApiProps = { apiStatus, query };

  const getQuery = useCallback(async () => {
    if (!isEnabled) {
      return;
    }

    try {
      setIsLoading(true);
      setHasError(false);
      const response = await fetch(`/judge-api/github?query=${query}`, {
        method: 'GET',
        credentials: 'include',
      });

      const json = await response.json();
      setApiResults(json?.data);
      if (json?.errors) {
        setError(json?.errors);
        setHasError(true);
      }
    } catch (error) {
      setError(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [isEnabled, query, setError, setHasError, setIsLoading]);

  useEffect(() => {
    if (apiStatus.error) {
      console.error(apiStatus.error);
    }
  }, [apiStatus.error]);

  useLayoutEffect(() => {
    if (query?.length >= 3) {
      void getQuery();
    }
  }, [getQuery, query?.length]);

  return [{ apiStatus: judgeApiProps.apiStatus, payload: results, query: query }, setQuery];
};

export default useGitHub;
